import axios from 'axios';

const dataApiUrl = 'https://us-east-2.aws.data.mongodb-api.com/app/data-tnohjma/endpoint/data/v1/action/find';
let accessToken = null;

// Function to get access token
export const getAccessToken = async () => {
    try {
        const response = await axios.post('https://us-east-2.aws.services.cloud.mongodb.com/api/client/v2.0/app/data-tnohjma/auth/providers/local-userpass/login', {
            username: 'mongouser',
            password: 'Mongo.21',
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data.access_token; 
    } catch (error) {
        throw new Error('Error fetching access token: ' + error.message);
    }
};

// Function to get Medium articles
export const getMediumArticles = async () => {
    try {
        if (!accessToken) {
            accessToken = await getAccessToken();
        }

        const response = await axios.post(dataApiUrl, {
            collection: "Medium",
            database: "Articles",
            dataSource: "Cluster0"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        const items = response.data.documents;
        if (!items.length) {
            throw new Error('No items found');
        }

        return items;

    } catch (error) {
        throw new Error('Error fetching items: ' + error.message);
    }
};
