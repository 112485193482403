import React from "react";
import WidgetPosts from "../../components/WidgetPosts/WidgetPosts";


const MediumArticles = () => {

  return (
    <div className={`nc-Repositories `}>
      <div className="w-full">
        <WidgetPosts />
              </div>
    </div>
  );
};

export default MediumArticles;
