import React, { FC } from "react";
import NcImage from "../../components/NcImage/NcImage";
import { TaxonomyType } from "../../data/types";
import Link from "../../components/Link";

// export interface CardCategory1Props {
//   className?: string;
//   taxonomy: TaxonomyType;
//   size?: "large" | "normal";
// }

const CardCategory1= ({
  className = "",
  size = "normal",
  taxonomy,
}) => {
  const {  name, href , thumbnail } = taxonomy;
  return (
    <Link
      href={href}
      className={`nc-CardCategory1 flex items-center ${className}`}
      
    >
      <NcImage
        alt=""
        containerClassName={`relative flex-shrink-0 ${
          size === "large" ? "w-20 h-20" : "w-12 h-12"
        } rounded-lg mr-4 overflow-hidden`}
        src={thumbnail || ""}
        fill
        className="object-cover"
        sizes="80px"
      />
      <div>
        <h2
          className={`${
            size === "large" ? "text-lg" : "text-base"
          } nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
        >
          {name}
        </h2>
      </div>
    </Link>
  );
};

export default CardCategory1;
