import { getAccessToken } from "./fetchMediumArticles";
import axios from "axios";

const dataApiUrl = 'https://us-east-2.aws.data.mongodb-api.com/app/data-tnohjma/endpoint/data/v1/action';
var accessToken=null;
export const getItemsCategory = async () => {
    try {
      // Assuming you have some function to fetch items from your API gateway
      if (!dataApiUrl) {
        throw new Error('DYNAMODB_API_URL environment variable is not defined.');
      }
      
      if (!accessToken) {
        accessToken = await getAccessToken();
        console.log(accessToken);
    }
      const response = await axios.post(`${dataApiUrl}/find`, {
        collection: "Categories",
        database: "Articles",
        dataSource: "Cluster0"
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
    });
  if (response.status!==200) {
        throw new Error('Failed to fetch items'+response.status);
      }
      console.log(response.data.documents);
      const items =  response.data.documents;
      console.log(items);
      if (!items.length) {
        throw new Error('No items found');
    }
       return items;
    } catch (error) {
      throw new Error('Error fetching items: ' + error.message);
    }
  };

export const postDataToDBCategory = async (item) => {

  try {
  
    if (!accessToken) {
      accessToken = await getAccessToken();
  }
    const response = await axios.post(`${dataApiUrl}/insertOne`, {
      collection: "Categories",
      database: "Articles",
      dataSource: "Cluster0",
      document:item
  }, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
      },
  });

    const responseData = await response.data;
    console.log(responseData); // Log the response from the server
    // Handle the response data as needed
  } catch (error) {
    console.error('Error:', error);
    // Handle errors
  }
}

// Call the postData function wherever needed in your component or application, passing the item object as an argument
export const deleteItemCategory = async (itemId) => {
  try {

    const response = await axios.post(dataApiUrl+"/deleteOne", {
      collection: "Categories",
      database: "Articles",
      dataSource: "Cluster0",
      filter:{"id":itemId}
  }, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
      },
  });

    // Handling the response
    const responseData = await response.data;
    console.log(responseData); // Log the response from the server
    // Handle the response data as needed
  } catch (error) {
    console.error('Error:', error);
    // Handle errors
  }
};

export const updateItemCategory = async (item) => {
  console.log(item);
  try {
   // Constructing the request body
   const body = {"collection": "Categories",
    "database": "Articles",
    "dataSource": "Cluster0",
    "filter":{"id":item.id},
     "update":{ $set: { "subcategory":item.subcategory,"color":item.color,"name":item.name,"taxonomy":item.taxonomy,"thumbnail":item.thumbnail } },
     "upsert":"true"  
  };
  console.log(body);

  const response = await axios.post(dataApiUrl+"/updateOne",body, {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
    },
});

    // Handling the response
    const responseData = await response.data;
    console.log(responseData); // Log the response from the server
    // Handle the response data as needed
  } catch (error) {
    console.error('Error:', error);
    // Handle errors
  }
};